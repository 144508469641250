<template>
    <div v-show="props.compType === 'entryExitSelect'">
        <el-form-item label="提示符">
            <el-input class="input" v-model="props.placeholder" placeholder="请输入提示符" />
        </el-form-item>
        <el-form-item label="出入类型">
            <div v-show='props.dataType ==="static"'>
                <div v-for="(item, index) in props.options" :key="index" class="select-item">
                    <el-input v-model="item.label" placeholder="选项名" size="small" :disabled="props.optionsDisabled"/>
                    <!-- <el-input placeholder="选项值" size="small" :value="item.value" @input="setOptionValue(item, $event)" :disabled="props.optionsDisabled"/> -->
                </div>
            </div>
        </el-form-item>
        <el-form-item label="栅格">
            <el-input-number v-model="props.span" :min="1" :max="24" />
        </el-form-item>
        <!-- <el-form-item label="栅格间隔">
            <el-input-number v-model="props.gutter" :min="0"></el-input-number>
        </el-form-item> -->
        <el-form-item label="标签宽度">
            <el-input-number v-model="props.labelWidth" :min="1" :max="200"></el-input-number>
        </el-form-item>
        <!-- <el-form-item label="显示标签">
            <el-switch v-model="props.showLabel" @change="handlerChangeLabel"></el-switch>
        </el-form-item> -->
        <el-form-item label="必填" >
            <el-switch v-model="props.required" :disabled="true"></el-switch>
        </el-form-item>
    </div>
</template>
<script>
    import {
        changeId
    } from '../mixin'
    import draggable from "vuedraggable";
    import {
        isNumberStr
    } from '../utils/index'
    export default {
        name: "inputConfig",
        props: ['props', 'getFormId'],
        components: {
            draggable
        },
        mixins: [changeId],
        data() {
            return {
                tempOptions: []
            }
        },
        methods: {
            handlerChangeLabel(val) {
                this.props.labelWidth = val ? '80' : '1'
            },
            setValue(val) {
                if (Array.isArray(val)) {
                    return val.join(',')
                }
                if (['string', 'number'].indexOf(val) > -1) {
                    return val
                }
                if (typeof val === 'boolean') {
                    return `${val}`
                }
                return val
            },
            onValueInput(str) {
                if (Array.isArray(this.props.value)) {
                    // 数组
                    this.$set(
                        this.props,
                        'value',
                        str.split(',').map(val => (isNumberStr(val) ? +val : val))
                    )
                } else if (['true', 'false'].indexOf(str) > -1) {
                    // 布尔
                    this.$set(this.props, 'value', JSON.parse(str))
                } else {
                    // 字符串和数字
                    this.$set(
                        this.props,
                        'value',
                        isNumberStr(str) ? +str : str
                    )
                }
            },
            setOptionValue(item, val) {
                item.value = isNumberStr(val) ? +val : val
            },
            addSelectItem() {
                this.props.options.push({
                    label: '',
                    value: ''
                })
            },
            multipleChange(val) {
                this.$set(this.props, 'value', val ? [] : '')
            },
            handlerChangeDataType(value) {
                if (value === 'static') {
                    this.props.options = [];
                    this.props.options = this.tempOptions;
                } else {
                    this.tempOptions = this.props.options;
                    this.props.options = [];
                }
            }
        },
        mounted() {}
    }

</script>
<style lang="scss" scoped>
    .input {
        width: 75%
    }

    .el-form-item /deep/ .el-input__inner {
        height: 32px;
    }

</style>
