<template>
    <div v-show="props.compType === 'dateTime'">
        <el-form-item label="标题">
            <el-input class="input" v-model="props.label"></el-input>
        </el-form-item>
        <!-- <el-form-item label="栅格间隔">
            <el-input-number v-model="props.gutter" :min="0"></el-input-number>
        </el-form-item> -->
        <el-form-item label="标签宽度">
            <el-input-number v-model="props.labelWidth" :min="1" :max="200"></el-input-number>
        </el-form-item>
        <!-- <el-form-item label="显示标签">
            <el-switch v-model="props.showLabel"></el-switch>
        </el-form-item> -->
        <el-form-item label="提示符">
            <el-input class="input" v-model="props.placeholder"></el-input>
        </el-form-item>
        <el-form-item label="必填">
            <el-switch v-model="props.required"></el-switch>
        </el-form-item>
        <el-form-item label="时间类型">
            <el-select class="input" 
            v-model="props.status" 
            @change="handlerFormatChange1">
                <el-option 
                    v-for="item in dateTypeOptions" 
                    :key="item.value" 
                    :label="item.label" 
                    :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
    </div>
</template>
<script>
    import { parseTime } from "@/libs/utils";

    const dateTimeFormat = {
        datetime: 'yyyy-MM-dd HH:mm:ss',
        daterange: 'yyyy-MM-dd',
    }

    const dateTimeFormatObj = {
        datetime: 'y-m-d h:i:s',
        daterange: 'y-m-d',
    }

    const dateTimeType = {
        datetime: 'datetime',
        daterange: 'date',
    }

    export default {
        name: "inputConfig",
        props: ['props', 'getFormId'],
        components: {},
        // mixins: [changeId],
        data() {
            return {
                dateTypeOptions: [
                    {
                        label: '年-月-日 时:分:秒',
                        value: 'datetime'
                    },
                    {
                        label: '年-月-日',
                        value: 'daterange'
                    },
                ]
            }
        },
        methods: {
            handlerFormatChange1(val) {
                // 处理选中value时间格式
                if (this.props.value) {
                    this.props.value = parseTime(this.props.value, dateTimeFormatObj[val])
                }
                this.$set(this.props, 'type', dateTimeType[val])
                this.props.format = dateTimeFormat[val];
                this.props['value-format'] = dateTimeFormat[val];
            },
            // handlerChangeValueFormat(val) {
            //     this.props['value-format'] = val;
            // }
        },
        // mounted() {}
    }

</script>
<style lang="scss" scoped>
    .input {
        width: 75%
    }
    .el-form-item /deep/ .el-input__inner {
        height: 32px;
    }
</style>
