<!-- 说明文字 -->
<template>
  <div v-show="props.compType == 'descriptionText'">
    <el-form-item label="说明文字">
      <el-input class="input" v-model="props.value"></el-input>
    </el-form-item>
    <el-form-item label="字体颜色">
        <el-color-picker v-model="props.color"></el-color-picker>
    </el-form-item>
    <el-form-item label="字体大小">
        <el-input-number v-model="props.fontSize" :min="1" :max="30"></el-input-number>
    </el-form-item>
  </div>
</template>
<script>
import {changeId} from '../mixin'
const defaultRules={
  'phone':{'rule':'0?(13|14|15|17|18|19)[0-9]{9}','msg':'您输入的电话号码不符合规则'},
  'email':{'rule':'\\w[-\\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\\.)+[A-Za-z]{2,14}','msg':'您输入的邮件地址不符合规则'},
  'number':{'rule':'^[0-9]*$','msg':'您输入的内容不符合纯数字规则'},
  'idcard':{'rule':'\\d{17}[\\d|x]|\\d{15}','msg':'您输入的身份证号码不符合规则'}
}
/**
 * input的配置项
 */
export default {
  name:"inputConfig",
  props:['props','getFormId'],
  mixins:[changeId],
  data(){
    return {
      val:'',
    }
  },
  methods:{
    handlerChangeLabel(val){
      this.props.labelWidth = val?'80':'1'
    },
    handlerSuffixSelectIcon(){
      this.iconDialogVisible_suffix = true;
    },
    handlerPrefixSelectIcon(){
      this.iconDialogVisible_prefix = true;
    },
    addRuleItem(){
      this.props.rules.push({
        rule: '',
        msg: ''
      })
    },
    handlerChangeRulesType(val){
      const obj = defaultRules[val];
      this.props.rules.push({
        rule: obj.rule,
        msg: obj.msg
      })
    }
  },
  mounted(){
  },
  watch:{
  }
}
</script>
<style scoped>
.input{
  width:75%
}
.rule-item{
 padding-top: 5px;
 padding-bottom: 5px;
}
.close-btn{
  text-align: center;
}
.close-icon:hover{
  cursor: pointer;
}
.close-btn >>> .el-icon-remove-outline{
  color:"red"
}
.el-form-item /deep/ .el-input__inner {
        height: 32px;
    }

</style>